<template>
  <div>
    <CCard class="c-card">
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CCardBody>
        <div class="card-header-title">
          <CCardTitle>General</CCardTitle>
          <CButton class="c-btn" color="light" @click="onUpdateProfile">Update Change</CButton>
        </div>

        <CForm>
          <CInput
            type="text"
            id="formControlName"
            label="Name"
            v-model="userData.name"
          />
          <CInput
            type="email"
            id="formControlEmail"
            label="Email address"
            v-model="userData.email"
          />
        </CForm>

        <div class="card-header-title">
          <CCardTitle>Plans</CCardTitle>
          <CButton class="c-btn" color="light" @click="redirectToPlanPage">Change Plan</CButton>
        </div>
        <CForm>
          <CInput
            disabled
            type="text"
            id="formControlPlan"
            label="Current Plan"
            :value="currentPlan"
          />
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { CIcon } from '@coreui/icons-vue';
import { cilList, cilShieldAlt } from '@coreui/icons';
import PricePlanApi from "@/lib/pricePlanApi";
import ProfileApi from "@/lib/profileApi";
  export default {
    components: {
      CIcon
    },
    data() {
      return {
        userData: {
          userName: null,
          email: null,
        },
        pricePlanApi: new PricePlanApi(),
        profileApi: new ProfileApi(),
        currentPlan: null,
        infoList: [],
      }
    },
    setup() {
      return {
        cilList,
        cilShieldAlt,
      }
    },
    mounted() {
      var self = this;

      this.userData = JSON.parse(window.localStorage.getItem('user'));
      console.log(this.userData)

      let plans;
      let userSub;

      self.pricePlanApi
        .getPlans()
        .then((response) => {
          plans = response
          self.pricePlanApi
          .getUserSubsById(this.userData.id)
          .then((response) => {
            userSub = response

            this.currentPlan = plans.pricePlan.find(el => el._id === userSub.results[0].pricePlanId).title
          })
          .catch(({data}) => {
            console.log("Error", data.message, "danger");
          });
          })
        .catch(({data}) => {
          console.log("Error", data.message, "danger");
        });
    },
    methods: {
      redirectToPlanPage() {
        this.$router.push({ path: "/pricing-plan" });
      },
      onUpdateProfile() {
        let payload = {
          name: this.userData.name,
          email: this.userData.email,
        }
        this.profileApi.updateProfile(this.userData.id, payload)
        .then((res) => {
          this.userData = res;
          window.localStorage.setItem('user', JSON.stringify(res));
          this.toast("Info", "Successfully update profile", "success");
        })
        .catch(({ data }) => {
          this.toast("Error", data.message, "danger");
        })
      },
      toast(header, message, color) {
        var self = this;
        self.infoList.push({
          header: header,
          message: message,
          color: color,
      });
    },
    },
    name: 'Profile'
  }
</script>

<style>
.c-card {
  max-width: 59rem;
  width: 100%;
  margin: 0 auto;
}

.card-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-btn {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 50px;
  width: 176px;
}

</style>
