let apiUrl = process.env.VUE_APP_API_URL;


class PricePlanApi {

    getPlans() {
      var url = apiUrl + 'plans'
        return api.call('get', url)
          .then(({ data }) => {
            return data;
        });
    }


    getUserSubsById(userId) {
      var url = apiUrl + `usersubs?status=active&userId=${userId}`
      return api.call('get', url)
        .then(({ data }) => {
          return data;
      });
    }
}

export default PricePlanApi;
